.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  
  .fadeIn {
    animation-name: fadeIn;
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
  
    to {
      opacity: 0;
    }
  }
  
  .fadeOut {
    animation-name: fadeOut;
  }