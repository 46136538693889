.empty-state {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p {
        margin-top: 1.2em;
        color: #AFADAD;
    }
}